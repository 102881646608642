/* Variables */
.DropzoneInput {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  border-radius: 0.25rem;
  position: relative;
  flex-direction: column;
  padding: 1rem 0.2rem;
}
.DropzoneInput.populated {
  border: 1px dashed #dddddd;
  background: rgba(0, 0, 0, 0.03);
  padding: 0;
}
.DropzoneInput:hover {
  border: 1px dashed #999;
}
.DropzoneInput label {
  line-height: 0;
}

.btn.btn-outline-primary.btn-sm {
  margin-right: 10px !important;
  margin-bottom: 0;
  color: #3e92cc;
  border: 1px solid #3e92cc;
}
.btn.btn-outline-primary.btn-sm:hover {
  color: white;
  background-color: #3e92cc;
}
.ImageDropzoneInput {
  position: relative;
}
.ImageDropzoneInput .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background: #3e92cc;
}

.ImageDropzoneInput.is-invalid .DropzoneInput {
  border: 1px solid #dc3545;
}

.ImageDropzoneInput.is-invalid + .invalid-feedback {
  display: block;
}
