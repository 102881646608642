body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:focus,
div:focus {
  outline: 0;
}

@font-face {
  font-family: 'Manrope';
  font-display: fallback;
  font-weight: 200 800;
  src: url('fonts/Manrope/Manrope-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-style: italic;
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin-top: 0; /* Removes the http link, page numbers and some chrome formatting from the printed page */
    margin-bottom: 0;
  }

  td {
    box-shadow: none !important;
  }

  .ignore-print {
    display: none !important;
  }

  .bp3-navbar {
    display: none;
  }
}
